<template>
  <base-popup
      :popup-model="show"
      :title="formTitle"
      :valid="!valid"
      :width="48"
      @onCancelButtonClick="closePopupHandler"
      @onSaveButtonClick="saveItemHandler"
  >
    <v-form
        :ref="formRef"
        v-model="valid"
        lazy-validation
    >
      <v-container>
        <v-layout>
          <v-flex xs12>
            <v-text-field
                v-model="currentItem.name"
                :rules="requiredFieldRule"
                label="Name"
                prepend-icon="create"
                required
            />
            <v-text-field
                v-model="currentItem.component"
                :rules="requiredFieldRule"
                label="Component"
                prepend-icon="mdi-package"
                required
            />
            <v-card-subtitle>Description</v-card-subtitle>
            <vue-editor
                class="des-ml-18"
                v-model="currentItem.description"
                :editorToolbar="customToolbar"
                :editorOptions="editorSettings"
            />
            <br>
            <v-file-input
                v-if="!currentItem.attachment"
                color="deep-purple accent-4"
                counter
                label="Payment Icon"
                placeholder="Select your files"
                prepend-icon="mdi-paperclip"
                clearable
                dense
                outlined
                :show-size="1000"
                @change="onChangeImageHandler($event)"
            >
              <template v-slot:selection="{ index, text }">
                <v-chip
                    v-if="index < 2"
                    color="deep-purple accent-4"
                    dark
                    label
                    small
                >
                  {{ text }}
                </v-chip>

                <span
                    v-else-if="index === 2"
                    class="text-overline grey--text text--darken-3 mx-2"
                >
        +{{ currentItem.attachment.length - 2 }} File(s)
      </span>
              </template>
            </v-file-input>

            <template v-if="currentItem.attachment">
              <v-row no-gutters class="mb-3">
                <v-col cols="6" md="6">
                  <v-img class="mb-5" :src="currentItem.attachment"/>
                </v-col>
                <v-col cols="6" md="6">
                  <v-btn
                      class="ml-5"
                      icon
                      color="error"
                      @click="onClearHandler"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </template>
            <v-layout class="custom-switch-btn" row>
              <v-flex xs6>
                <v-subheader class="switch-btn-title">Status</v-subheader>
              </v-flex>
              <v-flex xs6>
                <v-switch
                    v-model="currentItem.isActive"
                    color="primary"
                    dense
                    inset
                    prefix="Status">
                </v-switch>
              </v-flex>
            </v-layout>
            <v-layout class="custom-switch-btn" row>
              <v-flex xs6>
                <v-subheader class="switch-btn-title">Show as By Default</v-subheader>
              </v-flex>
              <v-flex xs6>
                <v-switch
                    v-model="currentItem.isByDefault"
                    color="primary"
                    dense
                    inset
                    prefix="By Default">
                </v-switch>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-container>
    </v-form>
  </base-popup>
</template>
<script>
import BasePopup from "./BasePopup";
import PopupMixin from "../mixins/Popup";
import { VueEditor } from "vue2-editor";

export default {
  name: 'MethodPopup',
  components: {BasePopup, VueEditor},
  mixins: [PopupMixin],
  data() {
    return {
      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline"],
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" }
        ],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
        [{ color: [] }, { background: [] }],
        ["blockquote", "code-block", "link"]
      ],
      editorSettings: {
        modules: {
        }
      }
    }
  },
  computed: {
    formTitle() {
      return this.isEditMode
          ? 'Edit Method'
          : 'Add Method'
    }
  }
}
</script>
<style lang="scss">
.des-ml-18{
  margin-left: 18px;
}
</style>
